<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家管理</li>
            <li class="breadcrumb-item active" aria-current="page">店家資料管理</li>
            <li class="breadcrumb-item active" aria-current="page">店家商品資訊</li>
        </ol>
        </nav>
        <hr class="w-100">
        <div class="col-12 row text-end mx-auto mb-3" v-if="role_list.store_admin_management.role.split(',')[1] === '1'">
            <div>
                <router-link :to="'/StoreProductManagementEdit/' + $route.params.store_id">
                    <button class="btn m-2 btn-success">新增</button>
                </router-link>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="table-responsive container-lg col-12">
                <table class="table table-striped table-hover text-center align-middle">
                    <thead class="table-dark">
                        <tr>
                            <th class="col-1">序號</th>
                            <th class="col-2">店家編號</th>
                            <th class="col-1">統一編號</th>
                            <th class="col-2">店家名稱</th>
                            <th class="col-2">商品名稱</th>
                            <th class="col-1">狀態</th>
                            <th class="col-3">功能</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in records" :key="item">
                            <td>{{ item.product_id }}</td>
                            <td>{{ item.store_code }}</td>
                            <td>{{ item.tax_id }}</td>
                            <td>{{ item.store_name }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.status }}</td>
                            <td>
                                <span>
                                    <router-link :to="'/StoreProductManagementView/' + item.store_id + '/' + item.product_id" v-if="role_list.store_admin_management.role.split(',')[0] === '1'">
                                        <button class="btn m-2 btn-primary">檢視</button>
                                    </router-link>
                                    <router-link :to="'/StoreProductManagementEdit/' + item.store_id + '/' + item.product_id" v-if="role_list.store_admin_management.role.split(',')[2] === '1'">
                                        <button class="btn m-2 btn-warning">編輯</button>
                                    </router-link>
                                    <button class="btn m-2 btn-danger" @click.prevent="delete_store_product_data(item)" v-if="role_list.store_admin_management.role.split(',')[3] === '1'">刪除</button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<<"
                            @click.prevent="onChangePage(1)">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<"
                            @click.prevent="onChangePage(current_page - 1)">
                            <span aria-hidden="true">&lsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" v-for="page in links" :key="page" 
                        :class="{'active': page.active, 'disabled': page.label === '...'}" v-bind:style="{'cursor': page.active ? null : page.label === '...' ? null : 'pointer'}">
                        <a class="page-link" @click.prevent="onChangePage(page.label)">{{ page.label }}</a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">"
                            @click.prevent="onChangePage(current_page + 1)">
                            <span aria-hidden="true">&rsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">>"
                            @click.prevent="onChangePage(last_page)">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="text-center mx-auto mb-3">
            <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="input-group justify-content-center">
                <span class="text-vertical-center">前往第</span>
                <input type="number" class="form-control m-2" style="flex: 0 0 auto; width: auto;" id="current_page" v-model="current_page" :min="1" :max="9999999999">
                <span class="text-vertical-center">頁</span>
                <div>  
                    <button class="btn m-2 btn-primary" @click.prevent="onChangePage(current_page)">前往</button>
                </div>
            </div>
        </div>
        <div class="mb-3"></div>
    </div>
</template>

<script>
import { 
    get_store_product_list_admin,
    delete_store_product_admin
} from '../../api/store_product_admin';
import { mapActions, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
    },
    data() {
        return {
            last_page: 1,
            current_page: 1,
            links: [{
                label: "1",
                active: true,
            }],
            records: [],
            delete_data: {},
            page: 1,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search(page = 1, page_change = false) {
            this.page = page;
            get_store_product_list_admin({page: page, store_id: this.$route.params.store_id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'StoreManagementList' });
                } else {
                    if (this.page === 1
                        && this.$route.fullPath === this.$route.path
                    ) {
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    } else {
                        this.$router.push({
                            name: 'StoreProductManagementList', 
                            query: {
                                page: this.page,
                            }
                        });
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    }  
                }
            })
        },
        delete_store_product_data(item) {
            this.delete_data = item;

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    denyButton: 'm-2 btn btn-danger',
                    cancelButton: 'm-2 btn btn-secondary',
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '提示訊息',
                html: "確認刪除以下商品資訊？<br><br>"
                    + "店家編號： "
                    + item.store_code
                    + "<br>統一編號： "
                    + item.tax_id
                    + "<br>店家名稱： "
                    + item.store_name
                    + "<br>商品名稱： "
                    + item.name
                    + "<br>",
                icon: 'warning',
                denyButtonText: '刪除',
                cancelButtonText: '取消',
                showConfirmButton: false,
                showCancelButton: true,
                showDenyButton: true,
                showCloseButton: true,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isDenied) {
                    this.delete_confirm();
                } else {
                    this.cancel();
                }
            })
        },
        cancel() {
            this.delete_data = {};
        },
        delete_confirm() {
            delete_store_product_admin(this.delete_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.delete_data = {};
                    this.search(1);
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: '刪除成功！',
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    })
                }
            }, () => {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '刪除失敗！',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            })
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "StoreManagementList"});
            }
        },
        onChangePage(page) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })
            if (page > this.last_page || page <= 0) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，無指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else if (page == this.page) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，已於指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else {
                this.search(page, true);
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.query.page !== undefined) {
            if (this.$route.query.page == 1) {
                this.search(this.$route.query.page, false);
            } else {
                this.search(this.$route.query.page);
            }
        } else {
            this.search(this.page);
        }
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>
